import React, { useEffect, Fragment } from "react"

import { Box, Grid, Typography } from "@material-ui/core"

import SEO from "src/components/seo"

const NotFound = () => {
  useEffect(() => {
    console.log("=== NotFound ===")
  })

  return (
    <Fragment>
      <SEO />

      <Grid container justify="center" alignItems="center" spacing={4}>
        <Grid item xs={12}>
          <Box letterSpacing={4} paddingY={10}>
            <Typography align="center">Erreur 404</Typography>
            <Typography align="center">Page non trouvée</Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default NotFound
